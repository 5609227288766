@mixin upperCase() {
  text-transform: uppercase;
}

@mixin subtitle-bit() {
  background-color: #e5eeef;
  border-radius: $base-border-radius;
  width: 100%;
  color: #63727d;
  height: 36px;
  font-weight: $typography-title-font-weight;
  margin-bottom: $base-margin;
  @include flex-start();

  > span {
    @include upperCase();
    margin: $base-margin;
    font-size: $typography-small;
  }
}

@mixin subtitle-bit-optional() {
  @include subtitle-bit();
  background-color: #eaecf2;
}

@mixin modal-header() {
  background-color: #f4f6f9;
  height: 100px;
  border-radius: $modal-border-radius;
}

@mixin modal-content() {
  border-radius: $modal-border-radius;
  min-width: 915px;
}

@mixin flex-start() {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin form-label() {
  @include upperCase();
  font-size: $typography-small;
}
