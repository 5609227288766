html,
body {
  padding: 0;
  margin: 0;
}
.ant-table-cell {
  max-width: none !important;
}

.editable-cell {
	position: relative;
}
.editable-cell-value-wrap {
	padding: 5px 12px;
	cursor: pointer;
}
.editable-row {
	&:hover {
		.editable-cell-value-wrap {
			padding: 4px 11px;
			border: 1px solid #d9d9d9;
			border-radius: 2px;
		}
	}
}
.ant-table-layout-fixed {
	table {
		table-layout: auto;
	}
}
