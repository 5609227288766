// Base Scaffolding Variables
$text-color: #63727d;
$heading-color: #2d2d2d;
$base-margin: 15px;
$base-padding: 15px;
$base-border-radius: 12px;

// Buttons
$btn-border-radius-base: 4px;

// Colors
$error-color: #e61c40;
$input-border-color: #dedede;
$input-color: #838485;
$fill-color-grey: #63727d;
$input-weight: 500;
$modal-header-color: #f4f6f9;
$sub-header-gray: #919da6;

// Paddings
$padding-lg: 50px;
$padding-md: 24px;

// Drawer
$drawer-body-padding: $padding-md;

// Layout
$layout-header-background: #ffffff;

// Form
$form-vertical-label-padding: 0;

// Modal
$modal-header-bg: #e7ebf2;

// PageHeader
$page-header-back-color: #e7ebf2;
$page-header-ghost-bg: #e7ebf2;

// Shadow
$box-shadow-base: 0px 15px 30px rgba(0, 0, 0, 0.08);

// Typography
// ---
$typography-title-font-weight: 500;
$typography-title-margin-bottom: 0;
$typography-small: 10px;

// Inputs
$base-input-width: 140px;

// Modal
$modal-border-radius: 8px;
$modal-body-padding: 24px;
$modal-header-padding: 24px;
